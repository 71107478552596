<template>
  <div>
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="header">
        Diagnostics
    </div>
    <div class='radio-toolbar'>
      <button class="nav-option move-right" :class="!existingTestView ? 'active' : ''" v-on:click="setSearchExistingTestView(false)">{{ $t('add_new_test') }}</button>
      <button class="nav-option move-left" :class="existingTestView ? 'active' : ''" v-on:click="setSearchExistingTestView(true)">{{ $t('search_existing_test') }}</button>
      </div>
    <div v-if="!existingTestView">
      <SearchComponent :selectedSearchCriteria="selectedSearchEpisodeCriteria" :search-options="searchEpisodeOptions" :searchPlaceholder="'header_search_patients'" @search-data-by-key="handleSearchEpisodes" />
      <div v-if="totalEpisodes!==0" id="diagnosticsPage" >
        <diagnostics-details-page
          :displayFieldValue="'patients'"
          :detailsLoading="episodeDetailsLoading"
          :selectedPage="selectedPage"
          :tableRows="episodeColumnsData"
          :columns="episodeColumns"
          :totalRows="totalEpisodes"
          :pageSize="pageSize"
          @turn-page="handlePageTurn"
          @handle-action="handleActionClick"
        />
      </div>
      <div v-if="!episodeDetailsLoading && totalEpisodes===0" class="center-text">
        {{ $t('no_episodes_to_display') }}
      </div>
      <div v-if="totalEpisodes===0" class="center-content">
        <p class="text-with-button">
          {{ 'If the patient you are searching for is not present you can create a new enrollment by clicking on the button below' }}
        </p>
        <button class="nav-option" :class="'active'" v-on:click="addEpisode()">{{ $t('_add_patient') }}</button>
      </div>
    </div>
    <div v-if="existingTestView">
      <SearchComponent :selectedSearchCriteria="selectedSearchTestCriteria" :search-options="searchTestOptions" :searchPlaceholder="'_search_tests'" @search-data-by-key="handleSearchTest" />
      <div v-if="totalTests!==0" id="diagnosticsPage">
      <diagnostics-details-page
        :displayFieldValue="'_tests'"
        :detailsLoading="testDetailsLoading"
        :selectedPage="selectedPage"
        :tableRows="testsRowsData"
        :columns="testsColumnsData"
        :totalRows="totalTests"
        :pageSize="pageSize"
        @turn-page="handlePageTurn"
        @handle-action="handleActionClick"
      />
      </div>
      <div v-else-if="!testDetailsLoading && totalTests===0" class="center-text">
        {{ $t('no_tests_to_display') }}
      </div>
      <div v-if="showTestDetails">
        <test-details
          @test-details-closed="closeTestDetails()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../../shared/components/Breadcrumbs.vue'
import DiagnosticsDetailsPage from './components/DiagnosticsDetailsPage.vue'
import TestDetails from './components/TestDetails.vue'
import SearchComponent from '../../../shared/components/SearchComponent.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Diagnostics',
  data () {
    return {
      searchEpisodeOptions: [
        { Value: 'id', Key: 'id' },
        { Value: 'name', Key: 'name' },
        { Value: 'primaryPhoneNumber', Key: 'primaryPhoneNumber' }
      ],
      selectedSearchEpisodeCriteria: 'id',
      selectedSearchTestCriteria: 'testId',
      searchTestOptions: [
        { Value: '_test_id', Key: 'testId' },
        { Value: '_episode_id', Key: 'episodeId' }
      ],
      breadcrumbs: [
        {
          label: 'Dashboard',
          link: '/dashboard/overview',
          class: ''
        },
        {
          label: '_diagnostics',
          link: '',
          class: 'active'
        }
      ],
      showTestDetails: false
    }
  },
  components: {
    Breadcrumbs,
    DiagnosticsDetailsPage,
    SearchComponent,
    TestDetails
  },
  methods: {
    ...mapActions('Diagnostics', [
      'setSearchKey',
      'setSearchValue',
      'loadEpisodeList',
      'setSearchExistingTestView',
      'loadTestList',
      'setCurrentPage',
      'fetchTestDetails',
      'setTestRequestId'
    ]),
    async handleSearchEpisodes (data) {
      const { searchKey, searchValue } = data
      this.setSearchKey(searchKey)
      this.setSearchValue(searchValue)
      await this.loadEpisodeList()
    },
    addEpisode () {
      this.$router.push('/dashboard/enrollment')
    },
    async handleSearchTest (data) {
      const { searchKey, searchValue } = data
      this.setSearchKey(searchKey)
      this.setSearchValue(searchValue)
      await this.loadTestList()
    },
    handlePageTurn (number) {
      if (number < 1 || number > this.numberPages) {
        return
      }
      this.setCurrentPage(number)
      if (this.existingTestView) {
        this.loadTestList()
      } else {
        this.loadEpisodeList()
      }
    },
    async handleActionClick (item, action) {
      if (action.displayKey === '_add_test') {
        this.$router.push(`/dashboard/diagnostics/add-test/${item.episodeId}`)
      } else if (action.displayKey === '_view_test') {
        this.showTestDetails = true
        await this.fetchTestDetails(action)
      } else if (action.displayKey === '_edit_test') {
        this.setTestRequestId(item.testRequestId)
        this.$router.push(`/dashboard/diagnostics/edit-test/${item.testRequestId}`)
      }
    },
    closeTestDetails () {
      this.showTestDetails = false
    }
  },
  computed: {
    ...mapState('Diagnostics', [
      'existingTestView',
      'totalEpisodes',
      'episodeDetailsLoading',
      'testDetailsLoading',
      'testsColumnsData',
      'testsRowsData',
      'totalTests',
      'numberPages',
      'selectedPage',
      'pageSize',
      'episodeColumnsData',
      'episodeColumns'
    ])
  }
}
</script>

<style scoped>
.header{
  font-family: 'IBM Plex Sans',serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  padding-bottom: 20px;
  align-items: center;
  letter-spacing: 0.005em;
  color: var(--day-select-color);
  padding-left: 10px;
}

#diagnosticsPage {
  margin-top: 100px;
}

.radio-toolbar {
    text-align: center;
    margin-bottom: 20px;
  }

  .nav-option {
  display: inline-block;
  cursor: pointer;
  background-color: var(--background-color-primary);
  text-transform: uppercase;
  color: var(--primary-theme);
  padding: 10px 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  border: 1px solid var(--primary-theme);
  min-width: 240px;
  text-align: center;
  height: 48px;
  margin: unset !important;
  border-radius: 8px;
}

.move-left {
  position: relative;
  border-radius: 0px 8px 8px 0px;
}
.move-right {
  position: relative;
  border-radius: 8px 0px 0px 8px;
}

.active {
  background-color: var(--primary-theme);
  color: white;
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.center-text {
  text-align: center;
  margin-top: 100px;
}

.text-with-button {
  padding-bottom: 20px;
}
</style>
