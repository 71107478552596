<template>
  <div id="searchBar" class="header-font" style="width: fit-content;">
      <div id="searchCriteria" class="ufpl-xs ufpr-xs">
          <div id="displaySearchCriteria" class="searchCriteriaBtn" @click="toggleSearchCriteriaDropdown">
              <span class="ufpr-xs dark-text">{{selectedSearchCriteriaDisplayValue}}</span>
              <span>
                  <font-awesome-icon icon="caret-down" class="fa-lg" />
              </span>
          </div>
          <div v-if="showSearchCriteriaDropdown" id="searchCriteriaDropdown">
            <div :class="['radio-container','ufpa-xs']">
                  <RadioGroup class="ufpl-xs"
                   :label="''"
                   v-model="selectedSearchCriteria"
                   :allOptions="searchOptions"
                   :name="'searchOptions'"
                   :onClick="toggleSearchCriteriaDropdown"/>
            </div>
          </div>
      </div>

      <div id="search">
          <div id="displaySearch">
              <input id="searchBarInput" type="text" :placeholder="$t(searchPlaceholder)" v-model="searchValue">
              <span class="searchBtn ufpa-xs" @click="handleSearch">
                  <font-awesome-icon icon="search" class="fa-lg"/>
              </span>
          </div>
      </div>

  </div>
</template>

<script>
import RadioGroup from './RadioGroup.vue'
export default {
  name: 'SearchComponent',
  components: {
    RadioGroup
  },
  data () {
    return {
      showSearchCriteriaDropdown: false,
      searchValue: ''
    }
  },
  computed: {
    selectedSearchCriteriaDisplayValue () {
      return this.$t(this.selectedSearchCriteria)
    }
  },
  props: {
    searchOptions: {
      type: Array
    },
    selectedSearchCriteria: {
      type: String,
      default: 'id'
    },
    searchPlaceholder: {
      type: String
    }
  },
  methods: {
    toggleSearchCriteriaDropdown () {
      this.showSearchCriteriaDropdown = !this.showSearchCriteriaDropdown
    },
    async handleSearch () {
      const data = { searchKey: this.selectedSearchCriteria, searchValue: this.searchValue }
      this.$emit('search-data-by-key', data)
      this.searchValue = ''
    }
  }
}
</script>

<style lang="scss" scoped>
#searchBarInput{
position: relative;
left: 10px;
caret-color: var(--day-select-color);
color: var(--day-select-color);
}
#searchBarInput::placeholder{
font-weight: 500;
color: var(--place-holder-text);
}
#searchBar{
display: flex;
flex-direction: row;
border: 1px solid var(--input-border);
border-radius: 4px;
width: 375px;
}
#displaySearchCriteria{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
min-width: 5rem;
font-weight: 400;
}
#displaySearch{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-items: center;
}
#search{
position: relative;
}
#searchCriteria{
position: relative;
padding: 2px;
display: flex;
flex-direction: row;
flex-wrap: nowrap;
align-items: center;
background: var(--input-disabled-bg);
border-radius:3px;
}
input{
border: none;
height: 24px;
}
input:focus{
outline: none;
}
#searchCriteriaDropdown{
position: absolute;
left: -2px;
top:118%;
min-height: 5rem;
width: 10rem;
overflow: hidden;
border: 1px solid var(--very-light-shadow);
border-radius: 4px;
display: flex;
flex-direction: column;
flex-wrap: nowrap;
}
.searchCriteriaBtn{
cursor: pointer;
color: $label;
}
.searchBtn{
cursor: pointer;
margin-left: 10px;
}
.fa-lg {
color: var(--text-table-data);
}
.radio-container{
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--background-color-primary-light-dark);
}
</style>
